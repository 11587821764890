import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, is_set_price): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-employee`, { start, length, is_set_price })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(name: any, mobile: any, email: any, address_one: any, address_two: any, address_three: any, state_id: any, city_id: any, pincode: any, password: any, status: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-employee`, { name, mobile, email, address_one, address_two, address_three, state_id, city_id, pincode, password, status })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-employee`,  { name, mobile, email, address_one, address_two, address_three, state_id, city_id, pincode, password, status,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  dealerViewDetails(id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}show-details-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  
  totalEmployeeCounts(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-total-employee`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }


}
