import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { log } from 'console';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  @ViewChild('auto') auto: any;

  ordersList: any = [];
  start: any = 0;
  page: any = 0;

  public currentUserRole = '';
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths: any = 15;
  public orderDetails: any = [];
  public orderManufacturer: any;
  public orderEditDetails: any = [];
  public loading = false;
  public currentUser: any;
  StatusLable: any;
  public newForm: UntypedFormGroup;
  public newFormFilter: UntypedFormGroup;
  public dowaloadLoder = 0;
  public pageAdvancedEllipses = 1;
  public submitted = false;
  public loadingForm = false;
  public progressMainTable: boolean = false;
  public allDealer: any;
  public employeeDropDown: any = [];
  public selectBasicLoading = false;
  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allProduct: any;

  constructor(private _coreConfigService: CoreConfigService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private ordersService: OrdersService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: UntypedFormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;

    if (this.currentUserRole == 'User') {
      this._coreConfigService.config = {
        layout: {
          menu: {
            hidden: true
          }
        }
      };
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.orderList(this.start, this.lengths);
    this.getCEmployeeDropDown();

    this.newForm = this._formBuilder.group({
      remarks: [''],
    });

    this.newFormFilter = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      order_status: [''],
      dealers: [''],
      employee_id: [null],
    });
  }


  get f() {
    return this.newForm.controls;
  }

  get ff() {
    return this.newFormFilter.controls;
  }

  getCEmployeeDropDown() {
    this.ordersService.getEmployeeList().subscribe(
      data => {
        this.selectBasicLoading = true;
        if (data.status) {
          this.selectBasicLoading = false;
          this.employeeDropDown = data.employee_data;
        } else {
          this._toastrService.error(data.errorMessage, 'Opps!',);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  orderList(my_start: any, my_lengths: any) {

    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.ordersService.getReportData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.ordersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.ordersService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.orderList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Order Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: response.error,
                text: response.errorMessage,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }


  orderReportListNew(my_start: any, my_lengths: any, type: any = 0) {

    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);

    if (type != 1) {
      if (this.ff.start_date.value !== null) {
        formdata.append('start_date', this.ff.start_date.value);
      }
      if (this.ff.end_date.value !== null) {
        formdata.append('end_date', this.ff.end_date.value);
      }

      if (this.ff.order_status.value !== null) {
        formdata.append('order_status', this.ff.order_status.value);
      }

      if (this.ff.dealers.value !== null && this.ff.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.ff.dealers.value.id);
      }
    }

    this.ordersService.getReportData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.ordersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.orderReportListNew(this.page, this.lengths, type);
  }


  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.orderReportListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.orderReportListNew(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, orderId: any) {
    this.loading = false;
    this.orderDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
    if (modalBasic._declarationTContainer.localNames[0] == "modalBasicEdit") {
      this.ordersService.getOrderEditDetails(orderId).subscribe(
        response => {
          if (response.status) {
            response.orders.order_details.forEach((value: any, index: any) => {
              if (response.orders.order_details[index].discount > 0) {
                response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
              }
            });


            this.orderEditDetails = response.orders;

            this.f.remarks.setValue(response.orders.remarks);
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
    else {
      this.ordersService.getOrderDetails(orderId).subscribe(
        response => {
          if (response.status) {
            response.orders.order_details.forEach((value: any, index: any) => {
              if (response.orders.order_details[index].discount > 0) {
                response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
              }
            });
            this.orderDetails = response.orders;
            this.orderManufacturer = response.employeeManufacturer;

          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderDetails.order_details.length; i++) {
      sum += this.orderDetails.order_details[i][index];
    }
    return sum;
  }

  getSumEdit(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderEditDetails.order_details.length; i++) {
      sum += this.orderEditDetails.order_details[i][index];
    }
    return sum;
  }

  orderchangeStatus(orderId: any, status: any) {

    switch (status) {
      case 0:
        this.StatusLable = 'Pending';
        break;
      case 1:
        this.StatusLable = 'Processing';
        break;
      case 2:
        this.StatusLable = 'Half Dispatch';
        break;
      case 3:
        this.StatusLable = 'Full Dispatch';
        break;
      default:
        this.StatusLable = 'Cancel';
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to change.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {

        this.ordersService.orderchangeStatus(orderId, status).subscribe(
          data => {
            if (data.status) {
              this.reloadList();
              this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
            else {
              this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
            this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });

          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Order Status Not Changed',
          'error'
        )
      }
    })
  }

  countChange(qty: any, id: any) {
    if (qty > 0) {
      for (var i = 0; i < this.orderEditDetails.order_details.length; i++) {
        if (this.orderEditDetails.order_details[i].id === id) {
          this.orderEditDetails.order_details[i].order_qty = parseFloat(qty);
          if (this.orderEditDetails.order_details[i].discount > 0) {
            this.orderEditDetails.order_details[i].sub_total = (this.orderEditDetails.order_details[i].sales_price - ((this.orderEditDetails.order_details[i].sales_price * this.orderEditDetails.order_details[i].discount) / 100)) * parseFloat(qty);
          }
          else {
            this.orderEditDetails.order_details[i].sub_total = this.orderEditDetails.order_details[i].sales_price * parseFloat(qty);
          }
        }
      }
    }
  }

  updateOrder() {
    this.loading = true;
    this.ordersService.updateOrderSave(this.orderEditDetails.order_details, this.f.remarks.value, this.orderEditDetails.id).subscribe(
      response => {
        this.loading = false;
        if (response.status) {
          this.modalService.dismissAll('modalBasicEdit');
          this.orderEditDetails = [];
          this._toastrService.success(response.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.reloadList();
        }
        else {
          this._toastrService.error(response.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  deleteProduct(id: any) {
    for (var i = 0; i < this.orderEditDetails.order_details.length; i++) {
      if (this.orderEditDetails.order_details[i].id == id) {
        this.orderEditDetails.order_details.splice(i, 1);
      }
    }
  }

  download(id: any, index: any = 0) {

    this.dowaloadLoder = id;
    this.ordersService.downloadPdf(id).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = this.ordersList[index].invoice_no + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  /**
  * Submit
  *
  * @param form
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;
      this.page = 0;
      this.pageBasicText = 1;
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', this.lengths);

      if (this.ff.start_date.value !== null) {
        formdata.append('start_date', this.ff.start_date.value);
      }
      if (this.ff.end_date.value !== null) {
        formdata.append('end_date', this.ff.end_date.value);
      }

      if (this.ff.order_status.value !== null) {
        formdata.append('order_status', this.ff.order_status.value);
      }

      if (this.ff.dealers.value !== null && this.ff.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.ff.dealers.value.id);
      }

      if (this.ff.employee_id.value !== null && this.ff.employee_id.value !== undefined) {
        formdata.append('employee_id', this.ff.employee_id.value);
      }

      this.ordersService.filter(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.submitted = false;
          if (data.status) {
            this.ordersList = data.data;
            this.collectionSize = data.recordsTotal;
            this.toggleSidebar('new-sidebar');
          }
          else {
            this.ordersList = [];
            this.collectionSize = 0;
            if (data.error !== undefined) {
              this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.ordersService.dealerSearchFilter(value).subscribe(
      data => {
        this.isLoading = false;
        if (data.status) {
          this.allDealer = data.dealer_data;
        }
        else {
          this.allDealer = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEvent(item: any) {

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  addNewProduct() {
    
     let dummy = {
      "id": this.orderEditDetails.order_details[this.orderEditDetails.order_details.length-1].id + 1,
      "product_id": 0,
      "full_name": "",
      "order_qty": 1,
      "sales_price": 0,
      "dispatch_qty": 0,
      "discount": 0,
      "sub_total": 0,
      "min_order_qty": 1,
      "is_new": 1
    };
    this.orderEditDetails.order_details.push(dummy);
  }

  // ################################################

  onChangeSearchProduct(value: string) {

    if (value.length >= 3) {
      this.allProduct = [];
      this.isLoading = true

      this.ordersService.productSearch(this.orderEditDetails.user_id, value).subscribe(
        data => {
          this.isLoading = false;
          if (data.status) {
            for (var i = 0; i < data.product_data.length; i++) {
              if (data.product_data[i].discount > 0) {
                data.product_data[i].discount_price = data.product_data[i].sales_price - ((data.product_data[i].sales_price * data.product_data[i].discount) / 100);
              }
            }
            this.allProduct = data.product_data;
          }
          else {
            this.allProduct = [];
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }

  selectEventProduct(item: any,index:any) {
    this.orderEditDetails.order_details[index].product_id = item.id;
    this.orderEditDetails.order_details[index].full_name = item.full_name;
    this.orderEditDetails.order_details[index].sales_price = item.sales_price;
    this.orderEditDetails.order_details[index].order_qty = item.min_order_qty;
    this.orderEditDetails.order_details[index].min_order_qty = item.min_order_qty;
    this.orderEditDetails.order_details[index].discount_price = item.discount_price ?? 0;
    this.orderEditDetails.order_details[index].discount = item.discount;    
    this.orderEditDetails.order_details[index].sub_total = item.min_order_qty * item.sales_price;
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  clearProduct(): void {
    this.auto.clear();
  }


}
