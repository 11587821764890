<!-- Footer -->
<p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://techfirst.co.in/" target="_blank">TechFirst Technologies</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>