<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Price Per Customers List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/price-per-customer/add">
                                      <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                      ><span class="d-none d-sm-inline-block">Add New</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th class="action-column">#</th>
                                            <th>Enterprise Name</th>
                                            <th>Owner Detail</th>
                                            <th>City / State</th>
                                            <th class="action-column">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="dealersList?.length != 0">
                                        <tr *ngFor="let dealer of dealersList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ dealer.name }}</td>
                                            <td>{{ dealer.owner_name }} <br /> {{ dealer.phone }}</td>
                                            <td>{{ dealer.city_name }} / {{ dealer.state_name }}</td>
                                            <td>
                                                <a class="btn btn-icon btn-sm btn-primary" routerLink="/price-per-customer/product/{{ dealer.id }}" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View Prices">
                                                    <i data-feather="eye"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="dealersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>