import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ExpenseMasterService } from 'app/_services/expense-master.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.scss']
})
export class ExpenseReportComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public expenseDataList: any = [];
  public loading = false;
  public newForm: UntypedFormGroup;
  public submitted = false;
  public loadingForm = false;
  public progressMainTable: boolean = false;
  public expenseCategoriesDropDown: any = [];
  public selectBasicLoading = false;

  constructor(private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private expenseMasterService: ExpenseMasterService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.loading = true;
    this.expenseReportList(1);
    this.getExpenseCategoriesDropDown();
    this.newForm = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      expense_category_id: [null],
    });
  }

  get f() {
    return this.newForm.controls;
  }

  expenseReportList(type: any = 1) {
    const formdata = new FormData();
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.expense_category_id.value != "") {
        formdata.append('expense_category_id', this.f.expense_category_id.value);
      }
    }
    this.expenseMasterService.getAllData(formdata).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.expenseDataList = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getExpenseCategoriesDropDown() {
    this.expenseMasterService.getExpenseCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.expenseCategoriesDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  /**
 * Submit
 *
 * @param form
 */
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.toggleSidebar('new-sidebar');
    this.expenseDataList = [];
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      const formdata = new FormData();
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.expense_category_id.value != "") {
        formdata.append('expense_category_id', this.f.expense_category_id.value);
      }

      this.expenseMasterService.getAllData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.expenseDataList = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

    /**
* Toggle the sidebar
*
* @param name
*/
toggleSidebar(name): void {
  this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
}


getSum(index: any): number {
  let sum = 0;
  for (let i = 0; i < this.expenseDataList.length; i++) {
    sum += this.expenseDataList[i][index];
  }
  return sum;
}

resetReport()
{
  this.newForm.reset();
  this.onSubmit();
}
}
