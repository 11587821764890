import { Component, ViewChild, AfterViewInit, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboadService } from 'app/_services/dashboad.service'
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from 'app/_services/orders.service';
import { CoreConfigService } from '@core/services/config.service';
import { ClipboardService } from 'ngx-clipboard';
import { colors } from 'app/colors.const';
import Swal from 'sweetalert2';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  constructor(private clipboardApi: ClipboardService, private _coreConfigService: CoreConfigService, private modalService: NgbModal, private ordersService: OrdersService, private _router: Router, private _authenticationService: AuthenticationService, public dashboadService: DashboadService, private _toastrService: ToastrService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this._coreConfigService.config = {
      layout: {
        menu: {
          hidden: false
        }
      }
    };
  }

  public todayOrder: any = 0;
  public allOrder: any = 0;
  public allProduct: any = 0;
  public allDealer: any = 0;
  public contentHeader: object
  public fastMoving: any = [];
  public slowMoving: any = [];
  public lastTowDayOrder: any = [];
  public pendingOrderWithDueDay: any = [];
  public stockAlert: any = [];

  public start: any = 0;
  public page: any = 0;
  public pageAdvancedEllipses = 1;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 5;
  public loading = false;
  public loadingDueDay = false;

  public pageStockAlert = 0;
  public lengthsStockAlert = 5;
  public loadingStockAlert = false;
  public collectionSizeStockAlert = 0;
  public pageAdvancedEllipsesStockAlert = 1;
  public pageBasicTextStockAlert = 1;
  public currentUserRole: any;

  public pageOrder = 0;
  public lengthsOrder = 5;
  public loadingOrder = false;
  public collectionSizeOrder = 0;
  public pageAdvancedEllipsesOrder = 1;
  public pageBasicTextOrder = 1;
  public is_stock_handle = 0;

  public orderDetails: any = [];

  public currentUser: any;
  public storeLink: any = '';

  // Bar Chart
  public loadingBar = true;
  private successColorShade = '#1c4772';
  private successColorShade2 = '#2A945D';
  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout
  private labelColor = '#000000';

  public barChart: any = [];

  public planExpired = false;
  public planExpiredDay = 0;

  public progressLatestOrder:boolean = false;
  public progressPendingOrder:boolean = false;
  public progressStockAlerts:boolean = false;

  public loadingCustOutstanding:boolean = false;
  public progressCustOutstanding:boolean = false;
  public custOutstandings:any = [];
  public collectionSizeCustOutstanding:number = 0;
  public pageAdvancedEllipsesCustOutstanding:number = 1;
  public lengthsCustOutstanding: any  = 5;
  public pageCustOutstanding: any = 0;

  public loadingCustOutstandingwithDay:boolean = false;
  public progressCustOutstandingwithDay:boolean = false;
  public custOutstandingswithDay:any = [];
  public collectionSizeCustOutstandingwithDay:number = 0;
  public pageAdvancedEllipsesCustOutstandingwithDay:number = 1;
  public lengthsCustOutstandingwithDay: any  = 5;
  public pageCustOutstandingwithDay: any = 0;



  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {

    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
    this.is_stock_handle = JSON.parse(localStorage.getItem('currentUser')).user.is_stock_handle;

    if (JSON.parse(localStorage.getItem('currentUser')).user.remainingDays <= 10) {
      this.planExpired = true;
      this.planExpiredDay = JSON.parse(localStorage.getItem('currentUser')).user.remainingDays;

      if (this.planExpiredDay <= 3) {
        Swal.fire({
          icon: 'warning',
          title: 'Plan Expiry Alert!',
          text: 'Your plan is expiring in ' + this.planExpiredDay + ' days.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });    
      }
    }


    if (this.currentUserRole == 'Client') {
      this.loading = true;
      this.loadingDueDay = true;
      this.loadingStockAlert = true;
      this.getStatistics();
      this.getPendingOrderList(this.page, this.lengths);

      if (this.is_stock_handle == 1) {
        this.getStockAlertList(this.pageStockAlert, this.lengthsStockAlert);
      }
      this.progressLatestOrder = true;
      this.getOrdersList(this.pageOrder, this.lengthsOrder);

      this.loadingCustOutstanding = true;
      this.getCustOutstanding(this.pageCustOutstanding, this.lengthsCustOutstanding);

      this.loadingCustOutstandingwithDay = true;
      this.getCustOutstandingwithDay(this.pageCustOutstandingwithDay, this.lengthsCustOutstandingwithDay);

    }
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }

  }

  getStatistics() {
    this.dashboadService.getData().subscribe(
      data => {
        this.loading = false;

        if (data.status) {
          this.todayOrder = data.data.todayOrder;
          this.allOrder = data.data.allOrder;
          this.allProduct = data.data.allProduct;
          this.allDealer = data.data.allDealer;
          this.fastMoving = data.data.fastMoving;
          this.slowMoving = data.data.slowMoving;

          this.barChart = {
            chartType: 'bar',
            datasets: [
              {
                data: data.data.orderChart.order,
                backgroundColor: this.successColorShade,
                borderColor: 'transparent',
                hoverBackgroundColor: this.successColorShade,
                hoverBorderColor: this.successColorShade,
                label: 'Order'
              },
              {
                data: data.data.orderChart.challan,
                backgroundColor: this.successColorShade2,
                borderColor: 'transparent',
                hoverBackgroundColor: this.successColorShade2,
                hoverBorderColor: this.successColorShade2,
                label: 'Dispatched'
              }
            ],
            labels: data.data.orderChart.date,
            options: {
              elements: {
                rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom'
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                display: false
              },
              tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: this.tooltipShadow,
                backgroundColor: colors.solid.white,
                titleFontColor: colors.solid.black,
                bodyFontColor: colors.solid.black
              },
              scales: {
                xAxes: [
                  {
                    barThickness: 20,
                    display: true,
                    gridLines: {
                      display: true,
                      color: this.grid_line_color,
                      zeroLineColor: this.grid_line_color
                    },
                    scaleLabel: {
                      display: true
                    },
                    ticks: {
                      fontColor: this.labelColor
                    }
                  }
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      color: this.grid_line_color,
                      zeroLineColor: this.grid_line_color
                    },
                    ticks: {
                      stepSize: 1,
                      min: 0,
                      // max: 5,
                      fontColor: this.labelColor
                    }
                  }
                ]
              }
            },
            legend: false
          };

          this.loadingBar = false;

        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getPendingOrderList(my_start: any, my_lengths: any) {
    this.dashboadService.getPendingOrder(my_start, my_lengths).subscribe(
      response => {
        this.loadingDueDay = false;
        if (response.status) {
          
          this.progressPendingOrder = false;

          this.pendingOrderWithDueDay = response.data;
          this.collectionSize = response.recordsTotal;
        }
        else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }


      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadPage(event: any) {
    this.progressPendingOrder = true
    this.page = this.lengths * (event - 1);
    this.getPendingOrderList(this.page, this.lengths);
  }


  getStockAlertList(my_start: any, my_lengths: any) {
    this.dashboadService.getStockAlertList(my_start, my_lengths).subscribe(
      response => {
        this.loadingStockAlert = false;
        this.progressStockAlerts = false;
        if (response.status) {
          this.stockAlert = response.data;
          this.collectionSizeStockAlert = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }

      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadStockAlertPage(event: any) {
    this.progressStockAlerts = true;
    this.pageStockAlert = this.lengthsStockAlert * (event - 1);
    this.getStockAlertList(this.pageStockAlert, this.lengthsStockAlert);
  }

  getOrdersList(my_start: any, my_lengths: any) {
    this.dashboadService.getOrdersList(my_start, my_lengths).subscribe(
      response => {
        this.loadingStockAlert = false;
        this.progressLatestOrder = false;
        if (response.status) {
          this.lastTowDayOrder = response.data;
          this.collectionSizeOrder = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadPageOrder(event: any) {
    this.progressLatestOrder = true;
    this.pageOrder = this.lengthsOrder * (event - 1);
    this.getOrdersList(this.pageOrder, this.lengthsOrder);
  }

  // modal Basic
  modalOpen(modalBasic, orderId: any) {
    this.orderDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.ordersService.getOrderDetails(orderId).subscribe(
      response => {
        if (response.status) {
          response.orders.order_details.forEach((value: any, index: any) => {
            if (response.orders.order_details[index].discount > 0) {
              response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
            }
          });
          this.orderDetails = response.orders;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderDetails.order_details.length; i++) {
      sum += this.orderDetails.order_details[i][index];
    }
    return sum;
  }

  copyText() {
    this.storeLink = JSON.parse(localStorage.getItem('currentUser')).user.store_alias;
    this.clipboardApi.copyFromContent('https://order.digitalorderbook.com/#/home?store=' + this.storeLink);
    this._toastrService.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }



  loadPageCustOutstanding(event: any) {
    this.progressCustOutstanding = true
    this.pageCustOutstanding = this.lengthsCustOutstanding * (event - 1);
    this.getCustOutstanding(this.pageCustOutstanding, this.lengthsCustOutstanding);
  }

  getCustOutstanding(my_start: any, my_lengths: any) {

    this.dashboadService.getCustOutstandings(my_start, my_lengths).subscribe(
      response => {
        this.loadingCustOutstanding = false;
        this.progressCustOutstanding = false;
        if (response.status) {
          this.custOutstandings = response.outstanding;
          this.collectionSizeCustOutstanding = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  loadPageCustOutstandingwithDay(event: any) {
    this.progressCustOutstandingwithDay = true
    this.pageCustOutstandingwithDay = this.lengthsCustOutstandingwithDay * (event - 1);
    this.getCustOutstandingwithDay(this.pageCustOutstandingwithDay, this.lengthsCustOutstandingwithDay);
  }

  getCustOutstandingwithDay(my_start: any, my_lengths: any) {
    this.dashboadService.getCustOutstandings(my_start, my_lengths).subscribe(
      response => {
        this.loadingCustOutstandingwithDay = false;
        this.progressCustOutstandingwithDay = false;
        if (response.status) {
          this.custOutstandingswithDay = response.outstandingWithDue;
          this.collectionSizeCustOutstandingwithDay = response.recordsTotal;
        } else if (response.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


}
