<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="knowledge-base-content">
            <div class="row kb-search-content-info match-height">
                <div class="col-md-12 col-sm-12 col-12 kb-search-content">
                    <div class="card text-center p-2">
                        <div class="pdf-viewer">
                            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="margin:auto;width: 100%; height: 600px;"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>