import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from 'app/_services/orders.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-wise-order-report',
  templateUrl: './product-wise-order-report.component.html',
  styleUrls: ['./product-wise-order-report.component.scss']
})
export class ProductWiseOrderReportComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public pageBasicText = 1;
  public loading = false;
  public currentUser: any;
  public StatusLable: any;
  public newForm: UntypedFormGroup;
  public submitted = false;
  public loadingForm = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allDealer: any;
  public allProduct: any;
  public keywordProduct = 'full_name';
  public page: any = 0;
  public start: any = 0;
  public collectionSize = 0;
  public lengths: any = 15;
  public ordersList: any = [];
  public progressMainTable: boolean = false;
  public orderDetails: any = [];
  public searchProduct: any = [];
  public pageAdvancedEllipses = 1;

  constructor(private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private ordersService: OrdersService, private modalService: NgbModal, private _formBuilder: UntypedFormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
  }

  ngOnInit(): void {
    this.newForm = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      dealers: [''],
      products: ['', Validators.required],
      order_status: ['']
    });
    this.newForm.controls.order_status.setValue('');
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.ordersService.dealerSearchFilter(value).subscribe(
      data => {
        this.isLoading = false;
        if (data.status) {
          this.allDealer = data.dealer_data;
        }
        else {
          this.allDealer = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEvent(item: any) {

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }




  onChangeSearchProduct(value: string) {

    if (value.length >= 3) {
      this.isLoadingProduct = true
      this.ordersService.productSearch(0, value).subscribe(
        data => {
          this.isLoadingProduct = false
          if (data.status) {
            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }

  }
  selectEventProduct(item: any) {

  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.orderReportListNew(this.page, this.lengths);
  }

  orderReportListNew(my_start, my_lengths) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;

      this.pageBasicText = 1;
      this.progressMainTable = true;
      const formdata = new FormData();

      formdata.append('start', my_start);
      formdata.append('length', my_lengths);

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.order_status.value !== null) {
        formdata.append('order_status', this.f.order_status.value);
      }

      if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.f.dealers.value.id);
      }

      if (this.f.products.value !== null && this.f.products.value !== undefined) {
        formdata.append('product_id', this.f.products.value.id);
      }

      this.ordersService.productWiseOrder(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.submitted = false;
          this.progressMainTable = false;
          if (data.status) {
              this.ordersList = data.data;
              this.searchProduct = data.product;
              this.collectionSize = data.recordsTotal;
          }
          else {
            this.ordersList = [];
            this.collectionSize = 0;
            if (data.error !== undefined) {
              this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  /**
  * Submit
  *
  * @param form
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;
      this.progressMainTable = true;
      this.page = 0;
      this.pageBasicText = 1;
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', this.lengths);

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.order_status.value !== null) {
        formdata.append('order_status', this.f.order_status.value);
      }

      if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.f.dealers.value.id);
      }

      if (this.f.products.value !== null && this.f.products.value !== undefined) {
        formdata.append('product_id', this.f.products.value.id);
      }

      this.ordersService.productWiseOrder(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.progressMainTable = false;
          this.submitted = false;
          if (data.status) {
            
              this.ordersList = data.data;
              this.searchProduct = data.product;
              this.collectionSize = data.recordsTotal;
           
          }
          else {
            this.ordersList = [];
            this.collectionSize = 0;
            if (data.error !== undefined) {
              this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  get f() {
    return this.newForm.controls;
  }


  // modal Basic
  modalOpen(modalBasic, orderId: any) {
    this.orderDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.ordersService.getOrderDetails(orderId).subscribe(
      response => {
        if (response.status) {

          response.orders.order_details.forEach((value: any, index: any) => {
            if (response.orders.order_details[index].discount > 0) {
              response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
            }
          });
          this.orderDetails = response.orders;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderDetails.order_details.length; i++) {
      sum += this.orderDetails.order_details[i][index];
    }
    return sum;
  }



}
