import { Component, OnInit, ElementRef } from '@angular/core';
import { ProductsService } from 'app/_services/products.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-products-add',
  templateUrl: './products-add.component.html',
  styleUrls: ['./products-add.component.scss']
})
export class ProductsAddComponent implements OnInit {

  public productId: any = 0;
  public loading = false;
  public error = '';
  public newForm: UntypedFormGroup;
  public submitted = false;
  public categoryDropDown: any;
  public unitDropDown: any;

  public isAddMode: boolean = true;
  public product_selected_photo_one: any = '';
  public product_selected_photo_two: any = '';
  public product_selected_photo_three: any = '';
  public product_selected_photo_four: any = '';

  public product_selected_photo_oneAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_twoAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_threeAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_fourAvatarImage: string = 'assets/images/upload_placeholder.png';
  public readerImg: string = '';
  constructor(private elementRef: ElementRef, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.getCategoryDropDown();
    this.getUnitDropDown();
    if (this.route.snapshot.params['id'] !== undefined) {
      this.productId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.productId;
    if (!this.isAddMode) {
      this.edit(this.productId);
    }

    this.newForm = this._formBuilder.group({
      category_id: ['0'],
      product_code: [''],
      product_name: [null, [Validators.required]],
      sales_price: [''],
      purchase_price: [''],
      discount: [''],
      size_title: [''],
      color_title: [''],
      unit: [''],
      packing_qty: [''],
      master_packing_qty: [''],
      stock_alert_qty: [''],
      product_photo_one: [''],
      product_photo_two: [''],
      product_photo_three: [''],
      product_photo_four: [''],
      is_active: ['1'],
      min_order_qty: ['1'],
      mrp: ['']
    });
    this.f.category_id.setValue(0);
    this.f.unit.setValue('');
    this.f.is_active.setValue(1);
  }
  get f() {
    return this.newForm.controls;
  }

  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getUnitDropDown() {
    this.productsService.getUnitDropDown().subscribe(
      data => {
        if (data.status) {
          this.unitDropDown = data.units;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
    }
    else {
      this.loading = true;
      const formdata = new FormData();
      formdata.append('product_photo_one', this.product_selected_photo_one);
      formdata.append('product_photo_two', this.product_selected_photo_two);
      formdata.append('product_photo_three', this.product_selected_photo_three);
      formdata.append('product_photo_four', this.product_selected_photo_four);
      formdata.append('category_id', this.f.category_id.value);
      formdata.append('product_code', this.f.product_code.value);
      formdata.append('product_name', this.f.product_name.value);
      formdata.append('sales_price', (this.f.sales_price.value !== null) ? this.f.sales_price.value : 0);
      formdata.append('purchase_price', (this.f.purchase_price.value !== null) ? this.f.purchase_price.value : 0);
      formdata.append('discount', (this.f.discount.value !== null) ? this.f.discount.value : 0);
      formdata.append('size_title', this.f.size_title.value);
      formdata.append('color_title', this.f.color_title.value);
      formdata.append('packing_qty', (this.f.packing_qty.value !== null) ? this.f.packing_qty.value : 0);
      formdata.append('master_packing_qty', (this.f.master_packing_qty.value !== null) ? this.f.master_packing_qty.value : 0);
      formdata.append('unit', this.f.unit.value);
      formdata.append('stock_alert_qty', (this.f.stock_alert_qty.value !== null) ? this.f.stock_alert_qty.value : 0);
      formdata.append('min_order_qty', (this.f.min_order_qty.value !== null) ? this.f.min_order_qty.value : 0);
      formdata.append('is_active', this.f.is_active.value);
      formdata.append('mrp', this.f.mrp.value);
      formdata.append('id', this.productId);

      this.productsService.add(formdata, this.productId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.productId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/products']);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  edit(id: any) {
    this.productsService.edit(id).subscribe(
      response => {
        if (response.status) {

          this.productId = response.product.id;
          this.f.product_code.setValue(response.product.product_code);
          this.f.product_name.setValue(response.product.product_name);
          this.f.sales_price.setValue(response.product.sales_price);
          this.f.purchase_price.setValue(response.product.purchase_price);
          this.f.discount.setValue(response.product.discount);
          this.f.size_title.setValue(response.product.size_title);
          this.f.color_title.setValue(response.product.color_title);
          this.f.unit.setValue(response.product.unit);
          this.f.packing_qty.setValue(response.product.packing_qty);
          this.f.master_packing_qty.setValue(response.product.master_packing_qty);
          this.f.stock_alert_qty.setValue(response.product.stock_alert_qty);
          this.f.min_order_qty.setValue(response.product.min_order_qty);
          this.f.mrp.setValue(response.product.mrp);
          if (response.product.category_id > 0) {
            this.f.category_id.setValue(response.product.category_id);
          }
          this.f.is_active.setValue(response.product.is_active);

          response.product.productImage.forEach((value: any, index: any) => {

            if (index == 0) {
              this.product_selected_photo_oneAvatarImage = value.product_photo;
            }
            if (index == 1) {
              this.product_selected_photo_twoAvatarImage = value.product_photo;
            }
            if (index == 2) {
              this.product_selected_photo_threeAvatarImage = value.product_photo;
            }
            if (index == 3) {
              this.product_selected_photo_fourAvatarImage = value.product_photo;
            }
          });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  uploadFile(event: any, type: any) {



    if (type == 1) {
      this.product_selected_photo_one = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_oneAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }



    } else if (type == 2) {
      this.product_selected_photo_two = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_twoAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }



    } else if (type == 3) {
      this.product_selected_photo_three = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_threeAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }


    } else {
      this.product_selected_photo_four = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_fourAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }


    }
  }

  checkNumber(event: any) {
    if (event.keyCode != 16 && event.keyCode != 107 && event.keyCode != 189 && event.keyCode != 109 && event.keyCode != 69) {
      //Do nothing 
    }
    else {
      event.target.value = 0;
    }
  }

}
