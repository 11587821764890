import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ProductInwardService } from 'app/_services/product-inward.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-inward',
  templateUrl: './product-inward.component.html',
  styleUrls: ['./product-inward.component.scss']
})
export class ProductInwardComponent implements OnInit {

  @ViewChild('auto') auto: any;
  public placeholder: string = 'Report By Product Name';
  public keyword = 'name';
  public isLoadings: boolean = false;
  public selectBasicLoading = false;
  public isLoadingSubmit: Boolean = false;
  public AisLoading: boolean = false;
  public allProduct: any;

  public productsInwardList: any = [];
  public start: any = 0;
  public page: any = 0;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public listInward = true;
  public listInwardReport = false;

  public productsInwardReportList: any = [];
  public reportStart: any = 0;
  public reportPage: any = 0;
  public reportPageBasicText = 1;
  public reportCollectionSize = 0;
  public reportLengths = 15;
  public product_id: any;

  public progressMainTable:boolean = false;
  public isModelLoading: Boolean =true;
  public inwardDetails:any = [];
  public inward:any;


  public product_selected_file: any;
  public product_selected_name: any = '';
  public product_selected_size: any;
  public loadingFrm = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;

  constructor(private _fb: FormBuilder, private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private productInwardService: ProductInwardService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loading = true;
    this.listInwardReport = false;
    this.productInwardList(this.start, this.lengths);
    this.newForm = this._fb.group({
      products: ['', Validators.required]
    });
  }

 

  productInwardList(my_start: any, my_lengths: any) {
    this.productInwardService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        this.listInwardReport = false;
        if (response.status) {
          this.productsInwardList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.listInwardReport = false;
    this.listInward = true;
    this.progressMainTable = true;
    this.productInwardList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.listInwardReport = false;
    this.progressMainTable = true;
    this.listInward = true;
    this.page = this.lengths * (event - 1);
    this.productInwardList(this.page, this.lengths);
  }

  loadPageLengths(event: any) {
    this.progressMainTable = true;
    if(this.listInward){
      this.lengths = event.target.value;
      this.productInwardList(this.start, this.lengths);
    }else{
      this.reportLengths = event.target.value;
      this.productReportInwardList(this.reportPage, this.reportLengths);
    }
  }

  onChangeSearch(value: string) {
    this.AisLoading = true;
    if (value != '') {
      this.productInwardService.productSearch(value).subscribe(
        data => {
          if (data.status) {
            this.AisLoading = false
            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.error(data.message, 'Error!');
          }
        },
        err => {
          this._toastrService.error(err.message, 'Opps!');
        }
      );
    } else {
      this._toastrService.warning('Enter Product Name', 'Warning!');
      setInterval(() => {
        this.AisLoading = false
      }, 3000);
    }
  }

  selectEvent(item: any) {
    this.product_id = '';
    if(item == undefined){
      this._toastrService.warning('Enter Product Name', 'Warning!');
    }else{
      this.loading = false;
      //this.listInward = false;
      //this.listInwardReport = true;
      this.product_id = item.id
      this.productReportInwardList(this.reportPage, this.reportLengths);
    }
  }

  clear(): void {
    this.auto.clear();
  }

  closePanel(): void {
    this.auto.close();
  }

  // getStockReport(){
  //   if(this.product_id == undefined){
  //     this._toastrService.warning('Enter Product Name', 'Warning!');
  //   }else{
  //     this.productReportInwardList(this.reportPage, this.reportLengths);
  //   }
  // }

  productReportInwardList(my_r_start: any, my_r_lengths: any) {
    
    this.productInwardService.getReportData(my_r_start, my_r_lengths, this.product_id).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.listInward = false;
          this.listInwardReport = true;
          this.productsInwardReportList = response.data;
          this.reportCollectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reportLoadPage(event: any) {
    this.progressMainTable = true;
    this.reportPage = this.reportLengths * (event - 1);
    this.productReportInwardList(this.reportPage, this.reportLengths);
  }

  closedStatic() {
   this.clear();
   this.closePanel();
   this.listInward = true;
   this.listInwardReport = false;
  }


  deleteInward(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productInwardService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.productInwardList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Inward Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  details(id: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.productInwardService.edit(id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.inward = data.inward;
              this.inwardDetails = data.inward_details;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  } 

  // modal excel
  modalOpen(modalBasic:any) {
    this.modalService.open(modalBasic, {
      size: 'md'
    });
  }

  download(id:any) {
    this.productInwardService.downloadExcelStockFile(id).subscribe(
      data => {
        if (data.status) {
          window.location.href = data.url;
        }else{
          this._toastrService.error('Something went wrong! Please Try Again...', 'Opps!');
        }
      },
      err => {
        this._toastrService.error('Something went wrong! Please Try Again...', 'Opps!');
      }
    );
  }

  uploadFile(event: any) {
    this.error = '';
    this.product_selected_file = event.target.files[0];
    this.product_selected_name = event.target.files[0]['name'];
    this.product_selected_size = event.target.files[0]['size'] / 1024 / 1024;
  }

  get f() {
    return this.newForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.error = 'Please Upload File';
    }
    else {
      this.error = '';
      this.loadingFrm = true;

      const formdata = new FormData();
      formdata.append('products', this.product_selected_file);

      this.productInwardService.uploadFile(formdata).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.modalService.dismissAll('modalBasic');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.loading = true;
            this.newForm.reset();
            this.error = '';
            this.product_selected_file = '';
            this.product_selected_name = '';
            this.product_selected_size = '';
            this.productInwardList(this.page, this.lengths);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

    }
  }
}
