export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Dashboard',
      MASTER: 'Master',
      CATEGORIES: 'Categories',
      DEALERS: 'Customers',
      PRODUCTS: 'Products',
      INWARD: 'Products Inward',
      ORDERS: 'Orders',
      DELIVERYCHALLAN: 'Delivery Challan',
      PRICEPERCUSTOMER: 'Price Per Customers',
      REPORTS: 'Reports',
      ORDER_REPORTS: 'Order Reports',
      CHALLAN_REPORTS: 'Challan Reports',
      MANUFACTURERS: 'Supplier',
      EMPLOYEES: 'Sales Executive',
      HELP: 'Help',
      CUSTOMER_GUIDE: 'Customer Guide',
      SUPPLIER_GUIDE: 'Supplier Guide',
      UNIT: 'Units',
      OUTSTANDING_REPORT: 'Outstanding Report',
      EXPENSE_REPORT: 'Expense Report',
      EXPENSE_CATEGORY: 'Expense Category',
      EXPENSE: 'Manage Expense',
      DELIVERYCHALLANREACHED: 'Delivery Challan Delivered',
      PRODUCTWISEORDERREPORT: 'Product Wise Order Report',
    }
  }
}
