import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExpenseMasterService } from '../../_services/expense-master.service';

@Component({
  selector: 'app-expense-manage',
  templateUrl: './expense-manage.component.html',
  styleUrls: ['./expense-manage.component.scss']
})
export class ExpenseManageComponent implements OnInit {

  public expenseList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public expenseId = 0;
  public error = '';
  public expenseCategoriesDropDown: any = [];

  constructor(private _router: Router, private route: ActivatedRoute, private expenseMasterService: ExpenseMasterService, private _authenticationService: AuthenticationService, private _formBuilder: FormBuilder, private _toastrService: ToastrService, private http: HttpClient, private _coreSidebarService: CoreSidebarService) { }

  ngOnInit(): void {
    this.expensesList(this.start, this.lengths);
    this.getExpenseCategoriesDropDown();
    this.newFormAdd = this._formBuilder.group({
      exp_date: ['', Validators.required],
      expense_category_id: ['', Validators.required],
      amount: ['', Validators.required],
      remark: [''],
    });

  }

  get f() {
    return this.newFormAdd.controls;
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    }
    else {
      this.loadingFrm = true;
      this.error = '';
      const { exp_date, expense_category_id, amount, remark } = this.newFormAdd.controls;
      this.expenseMasterService.add(exp_date.value, expense_category_id.value, amount.value, remark.value, this.expenseId).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.expenseId = 0
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar('new-sidebar');
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this.error = data.message;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  /**
  * Toggle the sidebar
  *
  * @param name
  */
  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.expenseId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  getExpenseCategoriesDropDown() {
    this.expenseMasterService.getExpenseCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.expenseCategoriesDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }



  expensesList(my_start: any, my_lengths: any) {
    this.expenseMasterService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.expenseList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.expensesList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.expensesList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.expensesList(this.start, this.lengths);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.expenseMasterService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Expense Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  edit(index: any) {

    this.expenseId = this.expenseList[index].id;
    this.f.exp_date.setValue(this.expenseList[index].edate);
    this.f.expense_category_id.setValue(this.expenseList[index].expense_category_id);
    this.f.amount.setValue(this.expenseList[index].amount);
    this.f.remark.setValue(this.expenseList[index].remark);

    this.toggleSidebar('new-sidebar', 1);

  }

}
