<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Orders List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-success ml-1" rippleEffect routerLink="/orders/add">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                    <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Customer Name</th>
                                            <th> Phone No</th>
                                            <th> Order No</th>
                                            <th> PO No</th>
                                            <th> Amount</th>
                                            <th> Order Date</th>
                                            <th> Expected D. Date</th>
                                            <th> Status</th>
                                            <th> Order BY</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="ordersList?.length != 0">

                                        <tr *ngFor="let order of ordersList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ order.customer_name }}</td>
                                            <td>{{ order.phone }}</td>
                                            <td>{{ order.invoice_no }}</td>
                                            <td>{{ order.po_number }}</td>
                                            <td>₹ {{ order.order_amount | number:'1.2-2' }}</td>
                                            <td>{{ order.order_date }}</td>
                                            <td>{{ order.expected_delivery }}</td>
                                            <td *ngIf="currentUserRole != 'User'">
                                                <div class="btn-group">
                                                    <div ngbDropdown *ngIf="order.status == 0">
                                                        <button ngbDropdownToggle
                                                            class="btn btn-gradient-warning btn-sm" type="button"
                                                            id="dropdownMenuButton101">
                                                            Pending
                                                        </button>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,1)">Sent for
                                                                delivery</a>
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,4)">Cancel</a>
                                                        </div>
                                                    </div>
                                                    <div ngbDropdown *ngIf="order.status == 1">
                                                        <button ngbDropdownToggle
                                                            class="btn btn-gradient-primary btn-sm" type="button"
                                                            id="dropdownMenuButton101">
                                                            Sent for delivery
                                                        </button>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,0)">Pending</a>
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,4)">Cancel</a>
                                                        </div>
                                                    </div>
                                                    <div ngbDropdown *ngIf="order.status == 4">
                                                        <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm"
                                                            type="button" id="dropdownMenuButton101">
                                                            Cancel
                                                        </button>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,0)">Pending</a>
                                                            <a ngbDropdownItem
                                                                (click)="orderchangeStatus(order.id,1)">Sent for
                                                                delivery</a>
                                                        </div>
                                                    </div>
                                                    <span class="badge badge-pill badge-light-primary mr-1"
                                                        *ngIf="order.status == 2">Half Dispatch</span>
                                                    <span class="badge badge-pill badge-light-success mr-1"
                                                        *ngIf="order.status == 3">Full Dispatch</span>
                                                </div>
                                            </td>
                                            <td *ngIf="currentUserRole == 'User'">
                                                <span class="badge badge-pill badge-light-warning mr-1"
                                                    *ngIf="order.status == 0">Pending</span>
                                                <span class="badge badge-pill badge-light-primary mr-1"
                                                    *ngIf="order.status == 1">Sent for delivery</span>
                                                <span class="badge badge-pill badge-light-primary mr-1"
                                                    *ngIf="order.status == 2">Half Dispatch</span>
                                                <span class="badge badge-pill badge-light-success mr-1"
                                                    *ngIf="order.status == 3">Full Dispatch</span>
                                                <span class="badge badge-pill badge-light-danger mr-1"
                                                    *ngIf="order.status == 4">Cancel</span>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="order.employ_name == null else second">
                                                    -
                                                </ng-container>
                                                <ng-template #second>
                                                    {{order.employ_name}}
                                                </ng-template>
                                            </td>
                                            <td>

                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow"
                                                        ngbDropdownToggle data-toggle="dropdown">
                                                        <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="download(order.id,ndx)"><i data-feather="download"
                                                                class="mr-50"></i><span>Download</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="modalOpen(modalBasic,order.id)"><i
                                                                data-feather="eye" class="mr-50"></i><span>View
                                                                Details</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="modalOpen(modalBasicEdit,order.id)"
                                                            *ngIf="order.status != 3 && order.status != 4"><i
                                                                data-feather="edit-2"
                                                                class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="delete(order.id)"
                                                            *ngIf="order.status != 3 && order.status != 4"><i
                                                                data-feather="trash"
                                                                class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="ordersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize"
                                    *ngIf="collectionSize > 0" [(page)]="pageAdvancedEllipses" [maxSize]="5"
                                    (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true">

                                </ngb-pagination>
                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderDetails?.length != 0">#{{
                                            orderDetails.invoice_no }} </h4>
                                        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderDetails?.length == 0">
                                            Order Details</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="orderDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="orderDetails?.length != 0" id="print-section">
                                            <div class="col-12 pt-1 overflow-auto">



                                                <table class="table" style="width:100%;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:5px;"
                                                                *ngIf="orderManufacturer.profile_image == null">
                                                                <img src="assets/images/logo/logo-text.png" />
                                                            </td>
                                                            <td style="padding:5px;"
                                                                *ngIf="orderManufacturer.profile_image != null">
                                                                <img [src]="orderManufacturer.profile_image" width="100"
                                                                    height="auto" />
                                                            </td>
                                                            <td align="right" style="padding:5px;">
                                                                <h3 style="margin: 2px;">{{ orderManufacturer.name }}
                                                                </h3>
                                                                <h5 style="margin: 2px;">Mobile : {{
                                                                    orderManufacturer.mobile }}</h5>
                                                                <h5 style="margin: 2px;">Email : {{
                                                                    orderManufacturer.email }}</h5>
                                                                <h5 style="margin: 2px;"
                                                                    *ngIf="currentUser.user.is_gst_print == 1">GST : {{
                                                                    orderManufacturer.gst_no | uppercase }}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table" style="width:100%;margin-top: 15px;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:5px;">
                                                                <b>Name : </b> {{ orderDetails.name }}<br />
                                                                <b>Phone : </b> {{ orderDetails.phone }} <br />
                                                                <b>Address : </b> {{ orderDetails.address_one }}, {{
                                                                orderDetails.address_two }}, {{
                                                                orderDetails.address_three }}<br />
                                                                <b>City : </b> {{ orderDetails.city_name }} - {{
                                                                orderDetails.state_name}}<br />
                                                                <b
                                                                    *ngIf="orderDetails.gst_no != '' && orderDetails.gst_no != null">GSTIN
                                                                    : </b> {{ orderDetails.gst_no }}
                                                            </td>
                                                            <td align="right" style="padding:5px;">
                                                                <b>Order No : </b> #{{ orderDetails.invoice_no }} <br />
                                                                <b
                                                                    *ngIf="orderDetails.po_number != null && orderDetails.po_number != ''">P.O.
                                                                    Number : </b> {{ orderDetails.po_number }} <br />
                                                                <b>Order Date : </b> {{ orderDetails.order_date }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-hover table-striped"
                                                    style="width:100%;margin-top:15px;">
                                                    <thead>
                                                        <tr style="font-weight:bold;">
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                Sr.No.</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                Product</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1">Rate</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                Qty</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                Dispatch</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                Remaining</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1">Sub Total
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let orderProduct of orderDetails.order_details; let sr = index">
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{sr+1}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                <div *ngIf="orderProduct.category_name != '' && orderProduct.category_name != null"
                                                                    class="font-small-2 badge badge-light-primary">{{
                                                                    orderProduct.category_name }} </div>
                                                                <br
                                                                    *ngIf="orderProduct.category_name != '' && orderProduct.category_name != null" />
                                                                {{ orderProduct.product_code }} {{
                                                                orderProduct.product_name }}
                                                                <small
                                                                    *ngIf="orderProduct.size_title != '' && orderProduct.color_title != ''"><br />{{
                                                                    orderProduct.size_title }} {{
                                                                    orderProduct.color_title }}</small>
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount == 0">
                                                                ₹ {{orderProduct.sales_price | number:'1.2-2'}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount > 0">
                                                                <small><del class="text-danger">₹
                                                                        {{orderProduct.sales_price |
                                                                        number:'1.2-2'}}</del></small><br />₹ {{
                                                                orderProduct.discount_price | number:'1.2-2'}}
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{orderProduct.order_qty}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{orderProduct.dispatch_qty}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{orderProduct.remaining_qty}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1">₹
                                                                {{orderProduct.sub_total | number:'1.2-2'}}</td>
                                                        </tr>
                                                        <tr style="font-weight:bold;">
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                colspan="3" *ngIf="currentUser.user.is_show_price == 1"
                                                                align="right" class="text-right">Total : &nbsp;&nbsp;
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                colspan="2" *ngIf="currentUser.user.is_show_price != 1"
                                                                align="right" class="text-right">Total : &nbsp;&nbsp;
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{ getSum('order_qty') }}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{ getSum('dispatch_qty') }}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                                                {{ getSum('remaining_qty') }}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1">₹ {{
                                                                getSum('sub_total') | number:'1.2-2'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table" style="width:100%;">
                                                    <tbody>
                                                        <tr *ngIf="orderDetails.remarks != ''">
                                                            <td><b>Remarks : </b> {{ orderDetails.remarks }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="padding:5px;background-color: #1c4772;text-align: center;color:rgb(194, 194, 194)">
                                                                Powered By : Digital Order Book
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                        <button type="button" class="btn btn-success" printSectionId="print-section"
                                            ngxPrint><i class="fa fa-print"></i> Print</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->

                                <!-- Edit Modal -->
                                <ng-template #modalBasicEdit let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabelEdit">Edit Order</h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="col" *ngIf="orderEditDetails?.length == 0">
                                            <div class="text-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="orderEditDetails?.length != 0">
                                            <div class="col-12 pt-1 overflow-auto">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding:5px;">
                                                                <b>Name : </b> {{ orderEditDetails.name }}<br />
                                                                <b>Phone : </b> {{ orderEditDetails.phone }}
                                                            </td>
                                                            <td align="right" style="padding:5px;">
                                                                <b>Order No : </b> #{{ orderEditDetails.invoice_no }}
                                                                <br />
                                                                <span
                                                                    *ngIf="orderEditDetails.po_number != '' && orderEditDetails.po_number != null">
                                                                    <b>Po No : </b> #{{ orderEditDetails.po_number }}
                                                                </span> <br />
                                                                <b>Order Date : </b> {{ orderEditDetails.order_date }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-hover table-striped">
                                                    <thead>
                                                        <tr style="font-weight:bold;">
                                                            <td>#</td>
                                                            <td>Product</td>
                                                            <td *ngIf="currentUser.user.is_show_price == 1">Rate</td>
                                                            <td class="text-center">Qty</td>
                                                            <td *ngIf="currentUser.user.is_show_price == 1">Sub Total
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let orderProduct of orderEditDetails.order_details; let sr = index">
                                                            <td>{{sr+1}}</td>
                                                            <td *ngIf="orderProduct.is_new && orderProduct.is_new == 1">
                                                                <ng-autocomplete #ngAutoCompleteStaticNew
                                                                    [data]="allProduct" [initialValue]=""
                                                                    [minQueryLength]="3"
                                                                    placeholder="Search Product, Min 3 Characters"
                                                                    [searchKeyword]="keywordProduct"
                                                                    historyIdentifier="allProduct"
                                                                    [historyHeading]="historyHeading"
                                                                    [historyListMaxNumber]="3" notFoundText="Not found"
                                                                    (selected)='selectEventProduct($event,sr)'
                                                                    (inputChanged)='onChangeSearchProduct($event)'
                                                                    (inputFocused)='onFocusedProduct($event)'
                                                                    [itemTemplate]="itemTemplateStatic01"
                                                                    [isLoading]="isLoading"
                                                                    [notFoundTemplate]="notFoundTemplate01">
                                                                </ng-autocomplete>
                                                                <ng-template #itemTemplateStatic01 let-item>
                                                                    <a [innerHTML]="item.full_name"></a>
                                                                </ng-template>
                                                                <ng-template #notFoundTemplate01 let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </td>
                                                            <td *ngIf="!orderProduct.is_new">

                                                                {{ orderProduct.full_name }}
                                                                <small
                                                                    *ngIf="orderProduct.size_title != '' && orderProduct.color_title != ''"><br />{{
                                                                    orderProduct.size_title }} {{
                                                                    orderProduct.color_title }}</small>
                                                            </td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount == 0">
                                                                ₹ {{orderProduct.sales_price | number:'1.2-2'}}</td>
                                                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;"
                                                                *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount > 0">
                                                                <small><del class="text-danger">₹
                                                                        {{orderProduct.sales_price |
                                                                        number:'1.2-2'}}</del></small> <br /> ₹ {{
                                                                orderProduct.discount_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                <core-touchspin *ngIf="orderProduct.dispatch_qty > 0"
                                                                    [numberValue]="orderProduct.order_qty" size="sm"
                                                                    [stepValue]="orderProduct.min_order_qty"
                                                                    [minValue]="orderProduct.dispatch_qty"
                                                                    [maxValue]="10000"
                                                                    (onChange)="countChange($event,orderProduct.id)"></core-touchspin>
                                                                <core-touchspin *ngIf="orderProduct.dispatch_qty == 0"
                                                                    [numberValue]="orderProduct.order_qty" size="sm"
                                                                    [stepValue]="orderProduct.min_order_qty"
                                                                    [minValue]="1" [maxValue]="10000"
                                                                    (onChange)="countChange($event,orderProduct.id)"></core-touchspin>
                                                            </td>
                                                            <td *ngIf="currentUser.user.is_show_price == 1">₹ {{
                                                                orderProduct.sub_total | number:'1.2-2'}}</td>
                                                            <td class="text-right">
                                                                <a (click)="deleteProduct(orderProduct.id)"
                                                                    *ngIf="orderProduct.dispatch_qty == 0"
                                                                    class="btn btn-icon btn-xs btn-danger"
                                                                    data-toggle="tooltip" data-popup="tooltip-custom"
                                                                    placement="top" container="body"
                                                                    ngbTooltip="Delete">
                                                                    <i data-feather="trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="6" class="text-right">
                                                                <a (click)="addNewProduct()"
                                                                    class="btn btn-icon btn-xs btn-success"
                                                                    data-toggle="tooltip" data-popup="tooltip-custom"
                                                                    placement="top" container="body"
                                                                    ngbTooltip="Add New Product">
                                                                    <i data-feather="plus"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="3" *ngIf="currentUser.user.is_show_price == 1"
                                                                align="right" class="text-right">Total : &nbsp;&nbsp;
                                                            </td>
                                                            <td colspan="2" *ngIf="currentUser.user.is_show_price != 1"
                                                                align="right" class="text-right">Total : &nbsp;&nbsp;
                                                            </td>
                                                            <td class="text-center">{{ getSumEdit('order_qty') }}</td>
                                                            <td *ngIf="currentUser.user.is_show_price == 1">{{
                                                                getSumEdit('sub_total') | number:'1.2-2' }}</td>
                                                            <td></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <form name="form" [formGroup]="newForm" autocomplete="off">
                                                            <textarea formControlName="remarks" class="form-control"
                                                                rows="2" id="remarks"
                                                                placeholder="Remark If Any">{{ orderEditDetails.remarks }}</textarea>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            (click)="modal.close('Accept click')" rippleEffect>Close </button>
                                        <button type="submit" class="btn btn-primary" (click)="updateOrder()"
                                            [disabled]="loading"> <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span> Update</button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormFilter" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Order Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Status</label>
                            <select formControlName="order_status" id="order_status" class="form-control">
                                <option value="" selected>All</option>
                                <option value="0">Pending</option>
                                <option value="1">Sent for delivery</option>
                                <option value="2">Half Dispatch</option>
                                <option value="3">Full Dispatch</option>
                                <option value="4">Cancel</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Customer Name</label>
                            <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" formControlName="dealers"
                                [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters "
                                [searchKeyword]="keyword" historyIdentifier="allDealer"
                                [historyHeading]="historyHeading" [historyListMaxNumber]="3" notFoundText="Not found"
                                (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic"
                                [isLoading]="isLoading" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplateStatic let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                        <div class="form-group">
                            <ng-select [items]="employeeDropDown" [loading]="selectBasicLoading" bindValue="id"
                                bindLabel="name" placeholder="Select Sales Executive" formControlName="employee_id">
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading"> <span
                                    *ngIf="loading" class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="reloadList(1)"
                                (click)="toggleSidebar('new-sidebar')" rippleEffect [disabled]="loading ">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 "></span>
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->