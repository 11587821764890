import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-guide',
  templateUrl: './customer-guide.component.html',
  styleUrls: ['./customer-guide.component.scss']
})
export class CustomerGuideComponent implements OnInit {

  public pdfSrc = 'assets/guides/user-guide.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
