import { Component, OnInit,ElementRef } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DealersService } from 'app/_services/dealers.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-dealers-add',
  templateUrl: './dealers-add.component.html',
  styleUrls: ['./dealers-add.component.scss']
})
export class DealersAddComponent implements OnInit {

  public dealerId = 0;
  public loading = false;
  public error = '';
  public newForm: UntypedFormGroup;
  public submitted = false;
  isAddMode: boolean = true;
  public stateDropDown: any;
  public cityDropDown: any;
  public guestMode: boolean = false;

  constructor(private elementRef: ElementRef,private _authenticationService: AuthenticationService, private dealersService: DealersService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    if (this.route.snapshot.params['id'] !== undefined) {
      this.dealerId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.dealerId;
    if (!this.isAddMode) {
      this.edit(this.dealerId);
    }

    this.newForm = this._formBuilder.group({
      enterprise_name: ['', Validators.required],
      owner_name: [null],
      phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
      alternate_phone: [null, [Validators.pattern('^[0-9]{10}')]],
      email: [null, [Validators.email]],
      address_one: [null],
      address_two: [null],
      address_three: [null],
      state_id: [null, [Validators.required]],
      city_id: [null, [Validators.required]],
      pincode: [null],
      gst_no: [null],
      status: [null],
      opening_balance: [null],
      opening_balance_type: [null],
      special_discount: [null]
    });
    this.f.state_id.setValue('');
    this.f.city_id.setValue('');
    this.f.status.setValue(0);
    this.f.opening_balance_type.setValue(0);
    this.getStateDropdown();
  }
  get f() {
    return this.newForm.controls;
  }


  edit(id: any) {
    this.dealersService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.dealerId = response.dealer.id;
          this.getCityUsingId(response.dealer.state_id);
          this.f.enterprise_name.setValue(response.dealer.name);
          this.f.owner_name.setValue(response.dealer.owner_name);
          this.f.phone.setValue(response.dealer.phone);
          this.f.alternate_phone.setValue(response.dealer.alternate_phone);
          this.f.email.setValue(response.dealer.email);
          this.f.address_one.setValue(response.dealer.address_one);
          this.f.address_two.setValue(response.dealer.address_two);
          this.f.address_three.setValue(response.dealer.address_three);
          this.f.state_id.setValue(response.dealer.state_id);
          this.f.city_id.setValue(response.dealer.city_id);
          this.f.pincode.setValue(response.dealer.pincode);
          this.f.gst_no.setValue(response.dealer.gst_no);
          this.f.status.setValue(response.dealer.status);
          this.f.opening_balance.setValue(response.dealer.opening_balance);
          this.f.opening_balance_type.setValue(response.dealer.opening_balance_type);
          this.f.special_discount.setValue(response.dealer.special_discount);
          if (response.dealer.status == 3)
            this.guestMode = true;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getStateDropdown() {
    this.dealersService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCity(event: any) {
    this.dealersService.getCity(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
          this.f.city_id.setValue('');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCityUsingId(stateId: any) {
    this.dealersService.getCity(stateId).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );

    firstInvalidControl.focus(); //without smooth behavior
  }
  /**
   * On Submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
    }
    else {
      this.loading = true;
      const { enterprise_name, owner_name, phone, alternate_phone, email, address_one, address_two, address_three, state_id, city_id, pincode, gst_no, status, opening_balance, opening_balance_type,special_discount } = this.newForm.controls;

      this.dealersService.add(enterprise_name.value, owner_name.value, phone.value, alternate_phone.value, email.value, address_one.value, address_two.value, address_three.value, state_id.value, city_id.value, pincode.value, gst_no.value, status.value, opening_balance.value, opening_balance_type.value,special_discount.value, this.dealerId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {

            this.dealerId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/dealers']);
          }
          else {

            if (data.error.phone) {
              this.error = data.error.phone[0];
            }
            else {
              this.error = data.error;
            }


          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  setGuestMode(event: any) {
    if (event.target.value != 3) {
      this.guestMode = false;
    }
  }

}
