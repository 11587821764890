<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">

                            <div class="card-developer-meetup mb-0">
                                <div class="card-body p-1">
                                    <div class="meetup-header mb-0 d-flex align-items-center">
                                        <div class="meetup-day">
                                            <div class="avatar bg-primary avatar-lg">
                                                <div class="avatar-content">{{ dealerDetails.name.substring(0, 1) }}</div>
                                            </div>
                                        </div>
                                        <div class="my-auto">
                                            <h4 class="card-title mb-25">{{ dealerDetails.name }}</h4>
                                            <p class="card-text mb-0">{{ dealerDetails.owner_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center m-1">
                                        <label class="d-flex align-items-center">Show
                                      <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-outline-dark ml-1" rippleEffect routerLink="/price-per-customer">
                                      <i data-feather="chevron-left" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Back</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Product Name</th>
                                            <th> Sale Price</th>
                                            <th> Sizes</th>
                                            <th> Colors</th>
                                            <th class="action-column">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsList?.length != 0">
                                        <tr *ngFor="let product of productsList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ product.product_name }}</td>
                                            <td>{{ product.sales_price | number:'1.2-2' }}</td>
                                            <td>{{ product.size_title }}</td>
                                            <td>{{ product.color_title }}</td>
                                            <td>

                                                <a (click)="editPrice(ndx)" class="btn btn-icon btn-sm btn-warning" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                                &nbsp;
                                                <a (click)="delete(product.id)" class="btn btn-icon btn-sm btn-danger" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="6" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Product Price</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="bs-stepper-content">
                            <!-- Checkout Place order starts -->
                            <div id="step-cart" class="content">
                                <div id="place-order" class="list-view product-checkout">
                                    <!-- Checkout Place Order Left starts -->
                                    <div class="checkout-items">
                                        <div class="row ecommerce-card">
                                            <div class="item-img col-3">
                                                <img src="assets/images/no-img.png" *ngIf="editProduct.product_photo == null" width=" 80 " alt="img" class="img-responsive " />
                                                <img [src]="editProduct.product_photo" *ngIf="editProduct.product_photo != null" width=" 80 " alt="img" class="img-responsive " />
                                            </div>
                                            <div class="card-body col-9 ">
                                                <div class="item-name ">
                                                    <h6 class="mb-0 ">
                                                        {{ editProduct.product_name }}
                                                    </h6>
                                                    <span class="item-company "> {{ editProduct.size_title }}  {{ editProduct.color_title }}</span>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <label for="register-sales_price" class="form-label">Product Price</label>
                                                    <input type="number" formControlName="sales_price" (keyup)="checkNumber($event)" class="form-control" placeholder="Product Price" aria-describedby="register-sales_price" [ngClass]="{ 'is-invalid': submitted && f.sales_price.errors }" />
                                                    <div *ngIf="submitted && f.sales_price.errors" class="invalid-feedback">
                                                        <div *ngIf="f.sales_price.errors.required">Product Price is required</div>
                                                    </div>
                                                </div>
                                                <button type="submit " class="btn btn-primary mr-1 " rippleEffect [disabled]="loading "> <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Save</button>
                                                <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loading" rippleEffect>
                                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                    <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                              </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->