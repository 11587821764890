import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ProfileService } from 'app/_services/profile.service';
import { DealersService } from 'app/_services/dealers.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public loading = false;
  public idEditMode = false;
  public isPasswordMode = false;
  public idBankDetails = false;
  public profile: any = '';
  public error = '';
  public newForm: UntypedFormGroup;
  public bankDetailsForm: UntypedFormGroup;
  public newFormPassword: UntypedFormGroup;
  public submitted = false;
  public stateDropDown: any;
  public cityDropDown: any;
  public avatarImage: string;
  public user_photo: any;
  public passwordTextType1: boolean;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  public submittedBankDetails: boolean = false;
  public loadingBankDetails: boolean = false;
  public paymentHistory: any = [];

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private dealersService: DealersService, private clipboardApi: ClipboardService, private _toastrService: ToastrService, private http: HttpClient, private profileService: ProfileService, private _formBuilder: UntypedFormBuilder, private customValidator: CustomvalidationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getProfile();
    this.avatarImage = 'assets/images/no-img.png';

    this.newForm = this._formBuilder.group({
      enterprise_name: ['', Validators.required],
      owner_name: [''],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10}')]],
      alternate_phone: ['', [Validators.pattern('^[0-9]{10}')]],
      email: ['', [Validators.required, Validators.email]],
      //address_one: ['', [Validators.required]],
      address_one: [''],
      address_two: [''],
      address_three: [''],
      state_id: [null, [Validators.required]],
      city_id: [null, [Validators.required]],
      pincode: [''],
      gst_no: [''],
      sms_alert: [''],
      stock_alert: [''],
      gst_display: [''],
      price_display: [''],
      is_deduct_stock: ['']
    });

    this.newFormPassword = this._formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
    });

    this.bankDetailsForm = this._formBuilder.group({
      account_name: [''],
      account_number: [''],
      ifsc_code: [''],
      google_pay: [''],
      phone_pay: [''],
      paytm: [''],
      bheem_upi: [''],
      remarks: [''],
    });

    this.fp.oldPassword.setValue('');
    this.fp.newPassword.setValue('');
    this.f.state_id.setValue('');
    this.f.city_id.setValue('');
    this.getStateDropdown();

  }
  get f() {
    return this.newForm.controls;
  }
  get fp() {
    return this.newFormPassword.controls;
  }

  get fbd() {
    return this.bankDetailsForm.controls;
  }

  getProfile() {
    this.profileService.getProfileData().subscribe(
      response => {
        if (response.status) {
          this.profile = response.user;
          this.getCityUsingId(response.user.state_id);
          this.f.enterprise_name.setValue(response.user.name);
          if (response.user.owner_name != null) {
            this.f.owner_name.setValue(response.user.owner_name);
          }
          this.f.phone.setValue(response.user.phone);
          let currentUser = JSON.parse(localStorage.getItem("currentUser"));

          if (response.user.profile_image != null) {
            this.avatarImage = response.user.profile_image;
            currentUser.user.profile_image = this.avatarImage;
          }
          currentUser.user.is_stock_handle = response.user.is_stock_handle;
          currentUser.user.is_gst_print = response.user.is_gst_print;
          currentUser.user.gst_no = response.user.gst_no;
          currentUser.user.is_show_price = response.user.is_show_price;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));

          if (response.user.alternate_phone != null) {
            this.f.alternate_phone.setValue(response.user.alternate_phone);
          }
          this.f.email.setValue(response.user.email);
          if (response.user.address_one != null) {
            this.f.address_one.setValue(response.user.address_one);
          }
          if (response.user.address_two != null) {
            this.f.address_two.setValue(response.user.address_two);
          }
          if (response.user.address_three != null) {
            this.f.address_three.setValue(response.user.address_three);
          }
          this.f.state_id.setValue(response.user.state_id);
          this.f.city_id.setValue(response.user.city_id);
          if (response.user.pincode != null) {
            this.f.pincode.setValue(response.user.pincode);
          }
          if (response.user.gst_no != null) {
            this.f.gst_no.setValue(response.user.gst_no);
          }

        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  editProfile() {
    this.idEditMode = true;
  }

  viewProfile() {
    this.idEditMode = false;
    this.isPasswordMode = false;
    this.idBankDetails = false;

  }

  changePassword() {
    this.isPasswordMode = true;
  }

  getStateDropdown() {
    this.dealersService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCity(event: any) {
    this.dealersService.getCity(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
          this.f.city_id.setValue('');
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getCityUsingId(stateId: any) {
    this.dealersService.getCity(stateId).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  /**
  * On Submit
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loading = true;
      const formdata = new FormData();
      if (this.user_photo !== undefined) {
        formdata.append('user_photo', this.user_photo);
      }
      formdata.append('enterprise_name', this.f.enterprise_name.value);
      formdata.append('owner_name', this.f.owner_name.value);
      formdata.append('phone', this.f.phone.value);
      formdata.append('alternate_phone', this.f.alternate_phone.value);
      formdata.append('email', this.f.email.value);
      formdata.append('address_one', this.f.address_one.value);
      formdata.append('address_two', this.f.address_two.value);
      formdata.append('address_three', this.f.address_three.value);
      formdata.append('state_id', this.f.state_id.value);
      formdata.append('city_id', this.f.city_id.value);
      formdata.append('pincode', this.f.pincode.value);
      formdata.append('gst_no', this.f.gst_no.value);

      let gst = this.f.gst_no.value;
      this.profileService.saveProfile(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.error = '';
            this.submitted = false;
            this.getProfile();
            this.idEditMode = false;
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
            currentUser.user.gst_no = gst;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            this._router.navigate(['/profile']);
          }
          else {
            this.error = data.error;
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.user_photo = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  /**
  * On Submit
  */
  onSubmitPassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormPassword.invalid) {

    }
    else {
      this.loading = true;
      const formdata = new FormData();
      formdata.append('current_password', this.fp.oldPassword.value);
      formdata.append('new_password', this.fp.newPassword.value);

      this.profileService.changePassword(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.newFormPassword.reset();
            this.fp.oldPassword.setValue('');
            this.fp.newPassword.setValue('');
            this.error = '';
            this.submitted = false;
            this.isPasswordMode = false;
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.message;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
  /**
   * Toggle password
   */
  togglePasswordTextType(me) {
    if (me == 1) {
      this.passwordTextType1 = !this.passwordTextType1;
    }
    else if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }

  copyText(store: any) {
    this.clipboardApi.copyFromContent('https://order.digitalorderbook.com/#/home?store=' + store);
    this._toastrService.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }

  getPayments() {
    this.profileService.paymentHistory().subscribe(
      data => {
        if (data.status) {
          this.paymentHistory = data.data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  modalOpen(modalBasic) {
    this.loading = false;
    this.paymentHistory = [];
    this.getPayments();
    this.modalService.open(modalBasic, {
      size: 'lg'
    });
  }


  bankDetailsFormMode() {
    this.idBankDetails = true;
    this.profileService.getBankDetailsData().subscribe(
      response => {
        if (response.status) {
          if (response.data.account_name !== null && response.data.account_name !== undefined) {
            this.fbd.account_name.setValue(response.data.account_name);
          }
          if (response.data.account_number !== null && response.data.account_number !== undefined) {
            this.fbd.account_number.setValue(response.data.account_number);
          }
          if (response.data.ifsc_code !== null && response.data.ifsc_code !== undefined) {
            this.fbd.ifsc_code.setValue(response.data.ifsc_code);
          }
          if (response.data.google_pay !== null && response.data.google_pay !== undefined) {
            this.fbd.google_pay.setValue(response.data.google_pay);
          }
          if (response.data.phone_pay !== null && response.data.phone_pay !== undefined) {
            this.fbd.phone_pay.setValue(response.data.phone_pay);
          }
          if (response.data.paytm !== null && response.data.paytm !== undefined) {
            this.fbd.paytm.setValue(response.data.paytm);
          }
          if (response.data.bheem_upi !== null && response.data.bheem_upi !== undefined) {
            this.fbd.bheem_upi.setValue(response.data.bheem_upi);
          }
          if (response.data.remarks !== null && response.data.remarks !== undefined) {
            this.fbd.remarks.setValue(response.data.remarks);
          }
        }
      });

  }
  /**
  * On Submit Bank Details
  */
  onSubmitBankDetails() {

    this.submittedBankDetails = true;
    // stop here if form is invalid
    if (this.bankDetailsForm.invalid) {

    }
    else {

      this.loadingBankDetails = true;
      const formdata = new FormData();
      formdata.append('account_name', this.fbd.account_name.value);
      formdata.append('account_number', this.fbd.account_number.value);
      formdata.append('ifsc_code', this.fbd.ifsc_code.value);
      formdata.append('google_pay', this.fbd.google_pay.value);
      formdata.append('phone_pay', this.fbd.phone_pay.value);
      formdata.append('paytm', this.fbd.paytm.value);
      formdata.append('bheem_upi', this.fbd.bheem_upi.value);
      formdata.append('remarks', this.fbd.remarks.value);

      this.profileService.saveBankDetailsData(formdata).subscribe(
        data => {
          this.loadingBankDetails = false;
          if (data.status) {
            this.bankDetailsForm.reset();
            this.error = '';
            this.submitted = false;
            this.idBankDetails = false;
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }


}
