import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public ordersList: any = [];
  public start: any = 0;
  public page: any = 0;
  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths: any = 15;
  public orderDetails: any = [];
  public loading = false;
  public currentUser: any;
  public StatusLable: any;
  public newForm: UntypedFormGroup;
  public submitted = false;
  public loadingForm = false;
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public allDealer: any;
  public dowaloadLoder: boolean = false;
  public pageAdvancedEllipses = 1;
  public progressMainTable:boolean = false;
  public employeeDropDown: any = [];
  public selectBasicLoading = false;

  constructor(private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService, private http: HttpClient, private ordersService: OrdersService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: UntypedFormBuilder) {

    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

  }

  ngOnInit(): void {
    this.loading = true;
    this.orderReportList(this.start, this.lengths);
    this.getCEmployeeDropDown();
    this.newForm = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      order_status: [''],
      dealers: [''],
      employee_id: [null],
    });
  }

  getCEmployeeDropDown() {
    this.ordersService.getEmployeeList().subscribe(
      data => {
        this.selectBasicLoading = true;
        if (data.status) {
          this.selectBasicLoading = false;
          this.employeeDropDown = data.employee_data;
        }else{
          this._toastrService.error(data.errorMessage, 'Opps!', );
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  orderReportList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.ordersService.getReportData(formdata).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.ordersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  orderReportListNew(my_start: any, my_lengths: any, type: any = 0) {

    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);

    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.order_status.value !== null) {
        formdata.append('order_status', this.f.order_status.value);
      }

      if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.f.dealers.value.id);
      }
    }

    this.ordersService.getReportData(formdata).subscribe(
      response => {
        this.loading = false;        
        this.progressMainTable = false;
        if (response.status) {
          this.ordersList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList(type: any = 0) {    
    this.progressMainTable = true;
    this.orderReportListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.orderReportListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.orderReportListNew(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, orderId: any) {
    this.orderDetails = [];
    this.modalService.open(modalBasic, {
      size: 'lg'
    });

    this.ordersService.getOrderDetails(orderId).subscribe(
      response => {
        if (response.status) {          
        
          response.orders.order_details.forEach((value: any, index: any) => {
            if (response.orders.order_details[index].discount > 0) {
              response.orders.order_details[index].discount_price = response.orders.order_details[index].sales_price - ((response.orders.order_details[index].sales_price * response.orders.order_details[index].discount) / 100);
            }
          });
          this.orderDetails = response.orders;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.orderDetails.order_details.length; i++) {
      sum += this.orderDetails.order_details[i][index];
    }
    return sum;
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  /**
  * Submit
  *
  * @param form
  */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;
      this.page = 0;      
      this.pageBasicText = 1;
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', this.lengths);

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.order_status.value !== null) {
        formdata.append('order_status', this.f.order_status.value);
      }

      if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
        formdata.append('dealer_id', this.f.dealers.value.id);
      }

      if (this.f.employee_id.value !== null && this.f.employee_id.value !== undefined) {
        formdata.append('employee_id', this.f.employee_id.value);
      }

      this.ordersService.filter(formdata).subscribe(
        data => {
          this.loadingForm = false;
          this.submitted = false;
          if (data.status) {
            this.ordersList = data.data;
            this.collectionSize = data.recordsTotal;
            this.toggleSidebar('new-sidebar');
          }
          else {
            this.ordersList = [];
            this.collectionSize = 0;
            if (data.error !== undefined) {
              this._toastrService.error(data.error, 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  get f() {
    return this.newForm.controls;
  }


  onChangeSearch(value: string) {
    this.isLoading = true
    this.ordersService.dealerSearchFilter(value).subscribe(
      data => {
        this.isLoading = false;
        if (data.status) {
          this.allDealer = data.dealer_data;
        }
        else {
          this.allDealer = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEvent(item: any) {

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  download() {

    this.dowaloadLoder = true;
    const formdata = new FormData();


    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }

    if (this.f.order_status.value !== null) {
      formdata.append('order_status', this.f.order_status.value);
    }

    if (this.f.dealers.value !== null && this.f.dealers.value.id !== undefined) {
      formdata.append('dealer_id', this.f.dealers.value.id);
    }

    this.ordersService.downloadReportPdf(formdata).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'order_reports.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.dowaloadLoder = false;

      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

}
