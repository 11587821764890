import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent  {

  public pdfSrc = 'assets/guides/supplier-user-guide.pdf';
  constructor() { }

  ngOnInit(): void {
  }

}
