import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ExpenseMasterService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-expense`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(exp_date: any,expense_category_id:any,amount:any,remark:any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-expense`, { exp_date,expense_category_id,amount,remark })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-expense`, { exp_date,expense_category_id,amount,remark,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-expense`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getExpenseCategoriesDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}expense-categories-dropdwon`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getAllData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}report-expense`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
