import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DeliveryChallanService } from 'app/_services/delivery-challan.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ToastrService } from 'ngx-toastr';
import { DeliveryChallanAddComponent } from "app/component/delivery-challan-add/delivery-challan-add.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery-challan',
  templateUrl: './delivery-challan.component.html',
  styleUrls: ['./delivery-challan.component.scss']
})
export class DeliveryChallanComponent implements OnInit {

  @ViewChild(DeliveryChallanAddComponent) child;

  public challansList: any = [];
  public start: any = 0;
  public page: any = 0;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public challanDetails: any = [];
  public currentUser: any;
  public dowaloadLoder = 0;
  public loadingPaddingOrderWiseDealer = true;
  public paddingOrderWiseDealerData: any = [];
  public pageAdvancedEllipses = 1;
  public progressMainTable: boolean = false;
  public submitted: boolean = false;
  public newForm: FormGroup;
  public transportform: FormGroup;
  public challanId: any = 0;
  public challanStatus = 0;
  public error: any = '';
  public StatusLable: any = '';

  constructor(private _formBuilder: FormBuilder, private _toastrService: ToastrService, private _router: Router, private http: HttpClient, private _authenticationService: AuthenticationService, private deliveryChallanService: DeliveryChallanService, private modalService: NgbModal, private _coreSidebarService: CoreSidebarService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
  }

  ngOnInit(): void {

    this.loading = true;
    this.challanList(this.start, this.lengths);
    this.paddingOrderWiseDealerList();

    this.newForm = this._formBuilder.group({
      remarks: [''],
    });

    this.transportform = this._formBuilder.group({
      lr_no: [''],
      transport_name: [''],
      mobile_no: [''],
      vehicle_no: [''],
      remarks: [''],
    });
  }
  get f() {
    return this.newForm.controls;
  }
  get tr() {
    return this.transportform.controls;
  }

  challanList(my_start: any, my_lengths: any) {
    this.deliveryChallanService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.challansList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  paddingOrderWiseDealerList() {
    this.deliveryChallanService.getpaddingOrderWiseDealerList().subscribe(
      response => {
        this.loadingPaddingOrderWiseDealer = false;
        if (response.status) {
          this.paddingOrderWiseDealerData = response.dealer_data;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.deliveryChallanService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.challanList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Delivery Challan Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
            else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.error,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  reloadList() {
    this.progressMainTable = true;
    this.challanList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.challanList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.challanList(this.start, this.lengths);
  }


  // modal Basic
  modalOpen(modalBasic, challanId: any) {
    this.challanDetails = [];
    this.modalService.open(modalBasic, {
      size: 'md'
    });

    this.deliveryChallanService.getChallanDetails(challanId).subscribe(
      response => {
        if (response.status) {
          this.challanDetails = response.delivery_challan;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );

  }
  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.challanDetails.delivery_challan_details.length; i++) {
      sum += this.challanDetails.delivery_challan_details[i][index];
    }
    return sum;
  }

  download(id: any, index: any = 0,type:any = 'C') {
    this.dowaloadLoder = id;
    this.deliveryChallanService.downloadPdf(id, type).subscribe(
      (data: Blob) => {
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        if(type == 'I')
        {
          a.download = 'Invoice_'+ this.challansList[index].invoice_no + '.pdf';
        }
        else if(type == 'S')
        {
          a.download = 'Sticker_'+ this.challansList[index].invoice_no + '.pdf';
        }
        else {
          a.download = this.challansList[index].invoice_no + '.pdf';
        }
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  /**
* Toggle the sidebar
*
* @param name
*/
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  editTransport(id: any, index: any = 0, modalTransport) {

    this.tr.lr_no.setValue(this.challansList[index].lr_no);
    this.tr.transport_name.setValue(this.challansList[index].transport_name);
    this.tr.mobile_no.setValue(this.challansList[index].mobile);
    this.tr.vehicle_no.setValue(this.challansList[index].vehicle_no);
    this.tr.remarks.setValue(this.challansList[index].remarks);

    this.challanId = this.challansList[index].id;
    this.modalService.open(modalTransport, {
      size: 'lg'
    });
  }

  updateTransportDetails() {
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {

      const formdata = new FormData();
      formdata.append('lr_no', this.tr.lr_no.value);
      formdata.append('transport_name', this.tr.transport_name.value);
      formdata.append('mobile_no', this.tr.mobile_no.value);
      formdata.append('vehicle_no', this.tr.vehicle_no.value);
      formdata.append('remarks', this.tr.remarks.value);
      formdata.append('id', this.challanId);

      this.deliveryChallanService.updateTransportDetails(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.modalService.dismissAll('modalTransport');
            this.submitted = false;
            this.transportform.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.reloadList();
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          this.loading = true;
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

      this.submitted = false;
    }
  }



  changeStatus(challnId: any, status: any, myModalLabelremark) {

    switch (status) {
      case 0:
        this.StatusLable = 'Not Reached';
        break;
      default:
        this.StatusLable = 'Reached';
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You Want to change.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {

        this.challanId = challnId;
        this.challanStatus = status;

        this.modalService.open(myModalLabelremark, {
          size: 'lg'
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Status Not Changed',
          'error'
        )
      }
    })
  }
  changeChallanStatus() {
    this.loading = true;

    this.deliveryChallanService.changeStatus(this.challanId, this.challanStatus, this.f.remarks.value).subscribe(
      data => {
        if (data.status) {
          this.reloadList();
          this.challanId = 0;
          this.challanStatus = 0;
          this.modalService.dismissAll('myModalLabelremark');
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });

      }
    );
  }

}
