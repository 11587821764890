import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DealersService {

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, is_set_price, enterprise_name: any = '', owner_name: any = '', phone: any = '', state_id: any = '', city_id: any = '', status: any = '', searchText: any = ''): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-dealer`, { start, length, is_set_price, enterprise_name, owner_name, phone, state_id, city_id, status, searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(enterprise_name: any, owner_name: any, phone: any, alternate_phone: any, email: any, address_one: any, address_two: any, address_three: any, state_id: any, city_id: any, pincode: any, gst_no: any, status: any, opening_balance: any, opening_balance_type: any, special_discount: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-dealer`, { enterprise_name, owner_name, phone, alternate_phone, email, address_one, address_two, address_three, state_id, city_id, pincode, gst_no, status, opening_balance, opening_balance_type, special_discount })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-dealer`, { enterprise_name, owner_name, phone, alternate_phone, email, address_one, address_two, address_three, state_id, city_id, pincode, gst_no, status, opening_balance, opening_balance_type, special_discount, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-dealer`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-dealer`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getState(): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .get(`${environment.apiUrl}get-state-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getCity(stateId: any): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .get(`${environment.apiUrl}get-cities-dropdown/` + stateId)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  dealerViewDetails(id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}show-details-dealer`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getPaymentListById(start, length, id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-wallet`, { start, length, id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addPayment(dealer_id, payment_choice, paying_amount, remarks): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}store-wallet`, { dealer_id, payment_choice, paying_amount, remarks })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  deletePayment(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-payment`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
