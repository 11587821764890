<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Product Wise Order Report</h4>
                        </div>
                        <div class="card-body p-0 pb-1">

                            <form class="" [formGroup]="newForm" (ngSubmit)="onSubmit()">
                                <div class="row m-0">
                                    <div class="col-md-3 col-12">
                                        <div class="form-group">
                                            <label class="form-label">Search Products<span class="text-danger">*</span></label>
                                            <ng-autocomplete #auto #ngAutoCompleteStatic [data]="allProduct" formControlName="products" [initialValue]="" [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters" [searchKeyword]="keywordProduct" historyIdentifier="allProduct" [historyHeading]="historyHeading"
                                                [historyListMaxNumber]="0" notFoundText="Not found" (selected)='selectEventProduct($event)' (inputChanged)='onChangeSearchProduct($event)' (inputFocused)='onFocusedProduct($event)' [itemTemplate]="itemTemplateStaticProduct"
                                                [isLoading]="isLoadingProduct" [notFoundTemplate]="notFoundTemplateProduct">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStaticProduct let-items>
                                                <a [innerHTML]="items.full_name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplateProduct let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>

                                            <div *ngIf="submitted && f.products.errors" class="text-danger">
                                                <div *ngIf="f.products.errors.required">Products is required</div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-2  col-6">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-md-2  col-6">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-md-2   col-12">
                                        <div class="form-group">
                                            <label class="form-label">Customer Name</label>
                                            <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" formControlName="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                                [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                                [notFoundTemplate]="notFoundTemplate">
                                            </ng-autocomplete>
                                            <ng-template #itemTemplateStatic let-item>
                                                <a [innerHTML]="item.name"></a>
                                            </ng-template>
                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="col-md-2   col-8">
                                        <div class="form-group">
                                            <label class="form-label">Status</label>
                                            <select formControlName="order_status" id="order_status" class="form-control">
                                                <option value="" selected >All</option>
                                                <option value="0">Pending</option>
                                                <option value="1">Sent for delivery</option>
                                                <option value="2">Half Dispatch</option>
                                                <option value="3">Full Dispatch</option>
                                                <option value="4">Cancel</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-1   col-4">
                                        <div class="form-group">
                                            <label class="form-label"></label><br/>
                                            <button type="submit" class="btn btn-primary mr-1 w-100" style="margin-top: 3px;" rippleEffect [disabled]="loadingForm"> <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>

                    <div class="card" *ngIf="searchProduct?.length != 0">
                        <div class="card-header">
                            <div class="row w-100">
                                <div class="item-img col-md-1 col-4">
                                    <img *ngIf="searchProduct.img == ''" src="assets/images/no-img.png" height="100" width="100" alt="IMG" class="rounded" />
                                    <img *ngIf="searchProduct.img != ''" [src]="searchProduct.img" height="100" width="100" alt="IMG" class="rounded">
                                </div>
                                <div class="card-body col-md-6 col-8">
                                    <div class="item-name ">
                                        <div *ngIf="searchProduct.category_name != '' && searchProduct.category_name != null" class="font-small-2 badge badge-light-primary"> {{ searchProduct.category_name }}</div>
                                        <h5 class="mb-0 ">
                                            {{ searchProduct.product_code }} {{ searchProduct.product_name }}
                                        </h5>
                                        <span class="item-company "> {{ searchProduct.size_title }}  {{ searchProduct.color_title }}</span>
                                    </div>
                                </div>
                                <div class="card-body col-md-5 col-12">
                                    <div class="row match-height">
                                        <div class="col-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <div class="media">
                                                        <div class="media-body my-auto">
                                                            <h4 class="font-weight-bolder mb-0">{{ searchProduct.counts.total_ordered }}</h4>
                                                            <p class="card-text font-small-3 mb-0">Total Ordered</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <div class="media">
                                                        <div class="media-body my-auto">
                                                            <h4 class="font-weight-bolder mb-0">{{ searchProduct.counts.total_dispatch }}</h4>
                                                            <p class="card-text font-small-3 mb-0">Total Dispatch</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <div class="media">
                                                        <div class="media-body my-auto">
                                                            <h4 class="font-weight-bolder mb-0">{{ searchProduct.counts.total_pending }}</h4>
                                                            <p class="card-text font-small-3 mb-0">Total Pending</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                            <div class="card text-center">
                                                <div class="card-body">
                                                    <div class="media">
                                                        <div class="media-body my-auto">
                                                            <h4 class="font-weight-bolder mb-0">{{ searchProduct.current_stock }}</h4>
                                                            <p class="card-text font-small-3 mb-0">Current Stock</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <p class="text-muted text-center"><b>Note :</b> All count with above filters.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="card-body p-0 pb-1" *ngIf="ordersList?.length != 0">
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> Customers Name</th>
                                            <th> Order No</th>
                                            <th> Product</th>
                                            <th> Order Qty</th>
                                            <th> Dispatch Qty</th>
                                            <th> Amount</th>
                                            <th> Order Date</th>
                                            <th> Status</th>
                                            <th> Order BY</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let order of ordersList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ order.customer_name }} <br/> {{ order.phone }}</td>
                                            <td>{{ order.invoice_no }}</td>
                                            <td>{{ order.product_code }} {{ order.product_name }}</td>
                                            <td>{{ order.order_qty }}</td>
                                            <td>{{ order.dispatch_qty }}</td>
                                            <td>{{ order.sub_total | number:'1.2-2' }}</td>
                                            <td>{{ order.order_date }}</td>
                                            <td>
                                                <span *ngIf="order.status == 0">Pending</span>
                                                <span *ngIf="order.status == 1">Sent for delivery</span>
                                                <span *ngIf="order.status == 2">Half Dispatch</span>
                                                <span *ngIf="order.status == 3">Full Dispatch</span>
                                                <span *ngIf="order.status == 4">Cancel</span>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="order.employ_name == null else second">
                                                    -
                                                </ng-container>
                                                <ng-template #second>
                                                    {{order.employ_name}}
                                                </ng-template>
                                            </td>

                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="ordersList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="10" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="10" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 0" [(page)]="pageAdvancedEllipses" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>


                            </div>

                        </div>
                    </div>



                </div>
            </div>
        </section>
    </div>
</div>